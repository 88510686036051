import React, { FunctionComponent } from "react";
import BlockContent from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";

import { ImageInterface, PortableText } from "../../types/SanityTypes";
import "./styles.scss";

interface TeaserWithFourImagesProps {
  _id: string;
  tag: string;
  _rawIntroduction: PortableText;
  _rawImages: ImageInterface[];
  textDescription: string;
  subIntroduction: string;
}

const TeaserWithFourImages: FunctionComponent<TeaserWithFourImagesProps> = props => {
  const { tag, _rawImages, _rawIntroduction, textDescription, subIntroduction } = props;

  return (
    <div className="teaserWithFourImages">
      <div className="teaserWithFourImages__introduction">
        <span className="teaserWithFourImages__introduction-tag">{tag}</span>
        <h2 className="teaserWithFourImages__introduction-text">
          {" "}
          <BlockContent blocks={_rawIntroduction} />
          <span>{subIntroduction}</span>
        </h2>
      </div>
      <div className="teaserWithFourImages__images">
        {_rawImages.map(item => (
          <div key={item._key}>
            {item && (
              <SanityImage
                {...item}
                width={0}
                height={0}
                htmlHeight={0}
                htmlWidth={0}
                className={`teaserWithFourImages__images-image`}
              />
            )}
          </div>
        ))}
      </div>
      <div className="teaserWithFourImages__description">
        <h2 className="teaserWithFourImages__description-text">{textDescription}</h2>
      </div>
    </div>
  );
};

export default TeaserWithFourImages;
